import { Link} from 'gatsby';
import React from 'react';
import ButtonLink from '../../components/ui/Buttons/ButtonLink';
import Masonry, {MasonryProps} from '../../components/ui/Masonry/Masonry';
import * as styles from './Themen.module.scss';
import { GatsbyImage } from "gatsby-plugin-image";
import classnames from "classnames";

export interface Tiles {
    kurzbeschreibung: string;
    published: Date;
    name: string;
    url?: string;
    listenbild?: any;
    tags?: Array<string>;
    type: "fallbeispiel" | "landingpage" | "news"
}

type Props = {
    tiles: Array<Tiles>;
    columns?: MasonryProps["columns"];
    itemsPerPage?: MasonryProps["itemsPerPage"];
}

const ThemenGrid = (props: Props) => {
    const {tiles, columns, itemsPerPage} = props;

    if (!tiles) {
        return null;
    }

        return (
            <Masonry columns={columns} itemsPerPage={itemsPerPage}>

                {tiles.map((node, key) => {

                    let url = [""];
                    if (node.url) {
                        switch(node.type) {
                            case "fallbeispiel":
                                url.push("fallbeispiele");
                        }
                        url.push(node.url);
                    }


                    return (
                        <div key={node.name} className={classnames([styles.tile, node.type === "news" ? styles.tileNews : ""])}>
                                {node.tags && <div className={styles.tags}>
                                    {node.tags.map((tag, index) => {
                                        return <span key={tag} className={classnames([index < node.tags.length -1 ? styles.withDivider : ""])}>{tag}</span> 
                                    })}
                                </div>}

                                {node.listenbild && !node.url && <GatsbyImage
                                    image={node.listenbild.src.childImageSharp.gatsbyImageData}
                                    className={classnames([styles.image])}
                                    alt={node.listenbild.alt} />}
                                {node.listenbild && node.url && <Link to={url.join("/")}><GatsbyImage
                                    image={node.listenbild.src.childImageSharp.gatsbyImageData}
                                    className={classnames([styles.image])}
                                    alt={node.listenbild.alt} /></Link>}


                                {!node.url && <h4 className={styles.title}>{node.name}</h4>}
                                {node.url && <Link to={url.join("/")}><h4 className={styles.title}>{node.name}</h4></Link>}


                                <p>{node.kurzbeschreibung}</p>

                                {node.url && <ButtonLink to={url.join("/")} variation="minimal">Mehr Informationen</ButtonLink>}
                        </div>
                    );
                })}

            </Masonry>
        );

}

export default ThemenGrid;