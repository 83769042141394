import React, { ReactElement } from 'react';
import * as styles from "./Masonry.module.scss";
import useBreakpoint from '../../../utils/useBreakpoint';
import MasonryCss from 'react-masonry-css';
import {chunk} from 'lodash';
import classnames from 'classnames';

interface NumberOfRows {
    mobile: number;
    tablet: number;
    desktop: number;
};

export type MasonryProps = {
    children: Array<ReactElement>;
    columns?: Partial<NumberOfRows>;
    itemsPerPage?: Partial<NumberOfRows>;
}

export default function Masonry(props: MasonryProps) {
    const { children } = props;

    const breakpoint = useBreakpoint();

    const [columns, setColumns] = React.useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(1);


    const [page, setPage] = React.useState<number>(1);

    const defaultColumns: NumberOfRows = {
        mobile: 1,
        tablet: 2,
        desktop: 3,
    }

    const defaultItemsPerPage: NumberOfRows = {
        mobile: 3,
        tablet: 4,
        desktop: 6,
    }

    React.useEffect(() => {
        setColumns(props?.columns && props.columns.hasOwnProperty(breakpoint) ? props.columns[breakpoint] : defaultColumns[breakpoint]);
        setItemsPerPage(props?.itemsPerPage && props.itemsPerPage.hasOwnProperty(breakpoint) ? props.itemsPerPage[breakpoint] : defaultItemsPerPage[breakpoint]);
    }, [breakpoint]);


    const itemPages = chunk(children, itemsPerPage);

    let itemsToDisplay = [];
    new Array(page).fill(1, 0, page).forEach((page, index) => {
        if (itemPages[index]) {
            itemPages[index].forEach((child, key) => {
                itemsToDisplay.push(child);
            });
        }
    });

    const loadMore = () => {
        setPage(page => page +1);
    }


    return <div>
        <MasonryCss
            breakpointCols={columns}
            className={styles.grid}
            columnClassName={styles.column}>
                {itemsToDisplay.map((child, key) => <div key={key} className={styles.wrapper}>{child}</div>)}
        </MasonryCss>

        {children.length > itemsToDisplay.length && <div className={classnames([styles.loadMoreAction, "foldAction has-text-centered has-text-link is-size-4"])} onClick={loadMore}>
                <span>Mehr anzeigen</span>

                <svg className={styles.chevron} width="18px" height="10px" viewBox="0 0 9 5" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M7.646,0.375l-3.616,3.687l-3.655,-3.687" style={{fill: "none", fillRule:"nonzero", stroke: "#009ee0", strokeWidth: 0.75}} /></svg>
            </div>}
  </div>
}